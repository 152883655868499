import React from "react"
import { Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
import Image from "../components/image"
import Blurbs from '../components/blurbs'

const IndexPage = () => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
        edges {
          node {
            id
            frontmatter {
              title
              filedIn
              date(formatString: "MMMM DD, YYYY")
              publish
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const publicPosts = edges.filter(
    ({
      node: {
        frontmatter: { publish },
      },
    }) => publish === true || publish === "true"
  )

  return (
    <Layout>
      <Head title="Home" />
      <div>
        <div
          style={{
            display: `flex`,
            justifyContent: `center`,
          }}
        >
          <Image
            style={{
              height: `200px`,
              width: `200px`,
              marginTop: `8px`,
              marginBottom: `24px`,
            }}
          />
        </div>
        <Blurbs />
      </div>

      <br />
      {publicPosts && publicPosts.length > 0 && (
        <div>
          <h3>Posts</h3>
          {publicPosts.map(
            ({
              node: {
                id,
                frontmatter: { title, filedIn, date },
                fields: { slug },
              },
            }) => {
              return (
                <div className="justify-between m-bottom-2" key={id}>
                  <div>
                    <Link to={`/post/${slug}`}>{title}</Link>
                    <br />
                    <small style={{ color: "gray" }}>{filedIn}</small>
                  </div>
                  <small>{date}</small>
                </div>
              )
            }
          )}
        </div>
      )}
    </Layout>
  )
}

export default IndexPage
