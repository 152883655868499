import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Image = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "images/me.jpeg" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)

  return (
    <img
      style={style}
      src={data.placeholderImage.childImageSharp.fixed.src}
      alt="kevin bielawski painting"
    />
  )
}

export default Image
