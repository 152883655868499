import React from "react"

const InitialBlurb = () => (
  <>
    <p>
      <span className="hi" role="img" aria-label="wave">
        👋
      </span>{" "}
      My name is Kevin Bielawski. I currently work as a tech lead at HubSpot
      where I build a collaborative, real-time customer support inbox.
    </p>
    <p>
      I graduated from Boston College in 2015. I studied economics and computer
      science and was a member of the Men's Rowing team. I served as a captain
      during my senior year and went on to compete at the 2015 World Rowing
      Under 23 Championships.
    </p>
    <p>
      Find me on <a href="https://twitter.com/blwsk">Twitter</a>,{" "}
      <a href="https://github.com/blwsk">GitHub</a>, and{" "}
      <a href="https://www.strava.com/athletes/3554016">Strava</a>.
    </p>
  </>
)

const BlurbOct2021 = () => (
  <>
    <p>
      <span className="hi" role="img" aria-label="wave">
        👋
      </span>{" "}
      My name is Kevin Bielawski.
    </p>
    <p>
      I currently work as a software engineer at{" "}
      <a href="https://poggiolabs.com">Poggio Labs</a>. We're building a new
      type of CRM. Previously, I was a tech lead at HubSpot where I built a
      collaborative, real-time customer support inbox.
    </p>
    <p>
      I graduated from Boston College in 2015. I studied economics and computer
      science and was a member of the Men's Rowing team. I served as a captain
      during my senior year and went on to compete at the 2015 World Rowing
      Under 23 Championships.
    </p>
    <p>
      Find me on <a href="https://twitter.com/blwsk">Twitter</a>,{" "}
      <a href="https://github.com/blwsk">GitHub</a>, and{" "}
      <a href="https://www.strava.com/athletes/3554016">Strava</a>.
    </p>
  </>
)

const blurbMap = {
  "October 2021": <BlurbOct2021 />,
  Initial: <InitialBlurb />,
}

const blurbs = Object.keys(blurbMap).map(key => (
  <div key={key}>{blurbMap[key]}</div>
))

const firstBlurb = blurbs[0]

function Blurbs() {
  return (
    <div className="blurbs">
      <div>{firstBlurb}</div>
    </div>
  )
}

export default Blurbs
